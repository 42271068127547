/* Global styles for Masqueraid App */

/* Apply a global box-sizing rule */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Body styling */
body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  background-color: #f4f4f4;
  color: #333;
  padding: 20px;
  text-align: center;
}

/* Links */
a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* Buttons */
button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #0056b3;
}

/* Error message styling */
.error {
  color: red;
  margin-top: 20px;
}

/* Styling for persona card */
.persona {
  margin: 20px 0;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.persona img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.persona h2 {
  margin-bottom: 10px;
}

.persona p {
  margin-bottom: 10px;
}

/* General heading styles */
h1 {
  color: #333;
  margin-bottom: 20px;
}

h2 {
  color: #007bff;
}
