/* src/App.css */

.App {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  padding: 20px;
  text-align: center;
}

h1 {
  color: #333;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error {
  color: red;
  margin-top: 20px;
}

.persona {
  margin: 20px 0;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.persona img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.persona h2 {
  margin-bottom: 10px;
}
